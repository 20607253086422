@import "../../common-styles.scss";

.objection-details-text-field {
  width: 100%;
}

.objection-reason {
  overflow: hidden;
}

.custom-control-label.checkboxError {
  color: #f44336 !important;
}

.custom-control-checkbox {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 1;
}

.objecting-values-warning {
  padding-top: 10px;
  border: solid 2px #ff9e1b;
  border-left: solid 30px #ff9e1b;
}

.no-contended-error-icon {
  fill: #ff9e1b;
  color: #ff9e1b;
  margin-left: -25px;
}

.no-contended-error-icon-checkbox {
  fill: #ff9e1b;
  color: #ff9e1b;
}
.contended-value {
  display: flex;
}

.warning-outer-div {
  display: inline-flex;
  z-index: 2;
}

.warning-icon {
  z-index: 1;
  color: white;
}

.helper-text-background {
  background-color: $helper-text-back-color;
  padding-top: 10px;
  padding-bottom: 10px;
}
