@import "../../common-styles.scss";

.page-sub-headings {
  padding-top: 20px;
}

.add-icon-size {
  font-size: 25px;
}

.add-property-area {
  cursor: pointer;
}

.verify-error-section {
  padding: 10px;
}

.notice-box {
  background-color: #d9d9d6;
  display: inline-flex;
  padding: 20px;
}

.notice-image {
  position: fixed;
  z-index: 0;
}

.errorText {
  color: $helper-text-color;
  margin: 20px;
  font-family: "VIC-BOLD", "Roboto", "Helvetica Neue", "Arial";
}

.errorText-council-details {
  margin: 20px;
  color: $helper-text-color;

  .cms-content {
    overflow-x: auto;
  }
}

.text-italic {
  font-style: italic;
}

.cms-content {
  color: $helper-text-color;
  overflow-x: auto;

  strong {
    font-weight: bold;
  }

  .wp-block-columns {
    display: inline-flex;

    .wp-block-column {
      padding-right: 50px;
    }
  }
}

.new-property-entry {
  margin-top: 3px;
  .row {
    margin-top: 15px;
  }
}

.rate-notice-img {
  .cms-content {
    img {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.property-values-error {
  border: solid 2px #ff9e1b;
  border-left: solid 30px #ff9e1b;
  min-height: 70px;
}

.property-values-contact {
  border: solid 2px #00b2a9;
  border-left: solid 30px #00b2a9;
  min-height: 70px;
}
