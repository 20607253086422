.breadcrumb-text {
  font-family: VIC, "Roboto", "Helvetica Neue", "Arial" !important;
}

.MuiBreadcrumbs-separator {
  margin-left: 4px !important;
  margin-right: 4px !important;
  padding-top: 10px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00b2a9 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #00b2a9 !important;
}

.stepper-aligned {
  text-align: center !important;
}

.MuiStepConnector-line {
  border-color: #00b2a9;
}
