.review-section-headings {
  border-bottom: 1px solid #53565a;
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.review-page-font {
  color: #53565a !important;
}

.review-page-field-value {
  font-weight: bold;
}

.review-page-field {
  display: inline-grid;
}

.centered-content {
  text-align: center;
}
