@import "../../common-styles.scss";

.bottom-footer {
  background-color: $footer-color;
  color: $white-color;
}

.footer-menu {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.footer-menu-item {
  line-height: 22.4px;
  font-family: $application-font-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  color: $white-color;
}

.footer-menu-list {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}

.footer-logo-group {
  text-align: center;
}

.footer-logo {
  padding-top: 35px;
}

.footer-logo-text {
  font-size: 14px;
  margin-top: 22px;
}

.footer-logo-link {
  height: 56px;
  width: 98px;
}
