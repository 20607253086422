header {
  font-family: VIC, "Roboto", "Helvetica Neue", "Arial" !important;
}

body {
  margin: 0;
  font-family: VIC, "Roboto", "Helvetica Neue", "Arial" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: VIC, "Roboto", "Helvetica Neue", "Arial" !important;
}

@font-face {
  font-family: "VIC";
  src: local("VIC"),
    url(./Fonts/WEBFONT/Regular/VIC-Regular.woff2) format("woff2"),
    url(./Fonts/WEBFONT/Regular/VIC-Regular.woff) format("woff"),
    url(./Fonts/WEBFONT/Regular/VIC-Regular.ttf) format("truetype");

  font-family: "VIC-BOLD";
  src: local("VIC-BOLD"),
    url(./Fonts/WEBFONT/Bold/VIC-Bold.woff2) format("woff2"),
    url(./Fonts/WEBFONT/Bold/VIC-Bold.woff) format("woff"),
    url(./Fonts/WEBFONT/Bold/VIC-Bold.ttf) format("truetype");
}
