$application-header-color: rgb(32, 22, 71) !important;
$footer-color: rgb(32, 22, 71) !important;
$application-font-regular: "VIC", "Arial", "sans-sarif" !important;
$sub-header: rgb(98, 91, 126) !important;
$white-color: #ffffff;
$button-background-color: #00b2a9 !important;
$button-text-color: #2c3742 !important;
$disabled-input-color: rgba(0, 0, 0, 0.65) !important;
$button-focused-background-color: #167a75 !important;
$required-error: #db1600 !important;
$stepper-color: #00c7b1 !important;
$helper-text-background-color: #c0ddf2 !important;
$helper-text-color: #53565a !important;
$helper-text-back-color: #d6d4dd;
$warning-box-color: #ff9e1b;
$error-color: #f44336;
$error-text-color: #db1600;
