.breadcrumb-text {
  font-family: VIC, "Roboto", "Helvetica Neue", "Arial" !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #00b2a9 !important;
}

.progress-circle-text {
  width: 100%;
  text-align: center;
}

.linear-heading {
  display: inline-flex;
  width: 100%;

  .page-header-text {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    color: #53565a !important;
  }

  .linear-progress-numbers {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    color: #53565a !important;
  }
}
