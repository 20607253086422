@import "../../common-styles.scss";

.top-header {
  background-color: $application-header-color;
  color: white;
  height: 100px;

  .logo {
    margin-top: 20px;
    height: 56px;
    width: 98px;
  }
}

.sub-header {
  background-color: rgb(98, 91, 126);
  height: 72px;
  padding-top: 24px;
}

.sub-header-text {
  padding-top: 8px;
  color: white;
  font-size: 20px;
}

.user-options {
  background-color: rgb(234, 234, 234);
  color: #53565a;
  min-height: 40px;
  width: 100%;
  display: inline-flex;
}

.user-options-text {
  padding-top: 8px;
  font-size: 20px;
  color: #53565a;
}

.user-options-text.active {
  font-weight: bold;
}

.header-logo {
  height: 56px;
  width: 98px;
}
